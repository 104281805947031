import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'gth-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class ConfirmDialogComponent {
  public title?: string;
  public description = 'This action is irreversible, be careful.';
  public additionalInformation?: string;
  public buttonText = 'Confirm';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string,
      description: string,
      additionalInformation?: string,
      buttonText?: string,
    },
  ) {
    this.title = data.title;
    this.description = data.description;
    this.additionalInformation = data.additionalInformation;
    if (data.buttonText) this.buttonText = data.buttonText;
  }
}
